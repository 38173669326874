import React from "react";
import "./style.css";
// import { ApiService } from "../services/ApiService";
import { Link } from "react-router-dom";
import axios from "axios";
// import widgetSchema from "./widgetSchema";

import {
  Layout,
  Button,
  PageHeader,
  Form,
  Input,
  Alert,
  Collapse,
  Spin,
} from "antd";

import Help from "./help";

const ApiService = { a: "b" };
const { Header, Content, Sider } = Layout;
const { Panel } = Collapse;

// Form styles
const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      install: false,
      settings: "",
      isJSLoading: true,
      loading: true,
      hasScript: false,
      scriptId: "",
      tkJS: "",
      tkToken: "",
      currentToken: "No token saved",
      currentJS: "No Javascript environment selected",
      hasTkWidgetTemplate: false,
      hasTkWidget: false,
      tkWidgetTemplateId: "",
      isSaved: false,
      submitErr: false,
      payload: "",
    };
  }

  initialize = () => {
    if (window.location.pathname == "/install/") {
      fetch("/auth/install-app" + window.location.search)
        .then((res) => res.text())
        .then((res) => {
          const data = JSON.parse(res);

          // Returns object stored in redis with store information.
          fetch(`/auth/get-user/${data.context.split("/")[1]}/${data.user.id}`)
            .then((res) => res.json())
            .then((res) => {
              // storeHash:
              this.setState({
                install: true,
                access_token: res["access_token"],
                storeHash: data.context.split("/")[1],
              });
              this.getScripts();
              this.getWidgets();
            });
        });
    } else if (window.location.pathname == "/load/") {
      fetch("/auth/load" + window.location.search)
        .then((res) => res.json())
        .then((res) => {
          this.setState({ payload: window.location.search });
          this.setState({
            access_token: res["access_token"],
            storeHash: res.context.split("/")[1],
          });
          this.getScripts();
          this.getWidgets();
        });
    }
  };
  // Extract token from widget-template. This grabs the first occurance of text between double quotes, which is the token.
  // Not the best solution but it will do for now.
  extractFirstText(str) {
    const matches = str.match(/"(.*?)"/);
    return matches ? matches[1] : str;
  }

  handleClose() {
    this.setState({ isSaved: false });
  }
  widgetSchema(token) {
    return {
      name: "Threekit",
      template: `<div id='tkplayer_{{assetId}}' style='height: {{ height }}; width: {{ width }}'></div><script>     window
            .threekitPlayer({
              authToken: "${token}",
              el: document.getElementById("tkplayer_{{assetId}}"),
              stageId: '{{ stageId }}',
              assetId: "{{ assetId }}",
              showConfigurator: "{{ showConfigurator }}",
              showAR: "{{ showAR }}"
            })
            .then(async api => {
               window.player = api;
               window.configurator = await api.getConfigurator();
        }); </script>`,

      schema: [
        {
          label: "Threekit Settings",
          type: "tab",
          sections: [
            {
              label: "Threekit Player Settings",
              settings: [
                {
                  id: "assetId",
                  label: "Threekit Product ID",
                  type: "input",
                },
                { id: "stageId", label: "Threekit Stage ID", type: "input" },

                {
                  id: "width",
                  label: "Player Width (%, px, vw)",
                  type: "input",
                },
                {
                  id: "height",
                  label: "Player Height (%, px, vw)",
                  type: "input",
                },
                { id: "showAR", label: "Enable AR", type: "boolean" },
                {
                  id: "showConfigurator",
                  label: "Show Config Form",
                  type: "boolean",
                },
              ],
            },
          ],
          meta: { tkid: "token" },
        },
      ],
    };
  }

  getScripts() {
    // fetch(
    //   `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/regions?templateFile=${escape('pages%2Fproduct')}`
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log(res);
    //   });
    fetch(
      `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/scripts`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.data.length == 0) {
          this.setState({ loading: false });
        } else
          res.data.forEach((e) => {
            if (e.name == "Threekit") {
              this.setState({
                isJSLoading: false,
                tkJS: e.src,
                hasScript: true,
                scriptId: e.uuid,
                loading: false,
                currentJS: e.src,
              });
            } else if (e.name != "Threekit") {
              this.setState({ loading: false });
            }
          });
      });
  }

  createScript(js) {
    // Create JS environment script
    axios
      .post(
        `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/scripts`,
        {
          payload: {
            name: "Threekit",
            description: "Enable configurable 3D and AR for your products",
            src: js,
            auto_uninstall: true,
            load_method: "default",
            location: "head",
            visibility: "storefront",
            kind: "src",
            consent_category: "functional",
          },
        }
      )
      .then((res) => {
        // Success
        this.setState({ isSaved: true, currentJS: res.data.data.src });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateScript(id, js) {
    // Create JS environment script
    axios
      .post(
        `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/scripts/${id}`,
        {
          payload: {
            name: "Threekit",
            description: "Enable configurable 3D and AR for your products",
            src: js,
            auto_uninstall: true,
            load_method: "default",
            location: "head",
            visibility: "storefront",
            kind: "src",
            consent_category: "functional",
          },
        }
      )
      .then((res) => {
        // Success
        this.setState({ isSaved: true, currentJS: res.data.data.src });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getWidgets() {
    fetch(
      `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/widget-templates`
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.length < 1) {
        } else {
          res.data.forEach((e) => {
            if (e.name == "Threekit") {
              this.setState({ hasTkWidgetTemplate: true });

              this.setState({
                currentToken: this.extractFirstText(e.template),
              });

              this.setState({ tkWidgetTemplateId: e.uuid });
            }
          });
        }
      });
  }

  createWidgetTemplate(token) {
    const formattedToken = this.widgetSchema(token);
    axios
      .post(
        `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/widget-templates`,
        {
          payload: this.widgetSchema(token),
        }
      )
      .then((res) => {
        // Success
        this.setState({
          isSaved: true,
          loading: false,
          currentToken: this.extractFirstText(res.data.data.template),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateWidgetTemplate(id, token) {
    const formattedToken = this.widgetSchema(token);
    axios
      .post(
        `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/widget-templates/${id}`,
        {
          payload: this.widgetSchema(token),
        }
      )
      .then((res) => {
        // Success
        this.setState({
          isSaved: true,
          loading: false,
          currentToken: this.extractFirstText(res.data.data.template),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  deleteWidgetTemplate(id) {
    axios
      .get(
        `/auth/bc-api/${this.state.access_token}/${this.state.storeHash}/content/widget-templates/${id}`
      )
      .then((res) => {
        // Success
        this.setState({
          isSaved: true,
          currentToken: "No token saved",
          hasTkWidgetTemplate: null,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.initialize();

 
  }

  render() {
    const handleChange = (e) => {
      this.setState({ [e.target.id]: e.target.value });
    };
    return (
      <div className="container">
        <PageHeader className="site-page-header" title="Threekit Setup">
          <Content>
            Welcome to Threekit's BigCommerce connector. To get started,
            authenticate your store by entering your Threekit details in the
            form below. Not sure where to start? See the help panel below.
            <br />
            <br />
            <p>
              <b>Current JS Environment: </b>
              {this.state.loading ? "loading..." : this.state.currentJS}
            </p>
            <p>
              <b>Current Org Token: </b>
              {this.state.loading ? "loading..." : this.state.currentToken}
            </p>
            {this.state.currentToken != "No token saved" ? (
              <div>
                <Collapse>
                  <Panel header={"Uninstall Directions"}>
                    Delete Threekit widget before uninstalling the app. If you
                    accidentally delete the widget, save your org token to
                    re-create it.
                    <br />
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        this.deleteWidgetTemplate(this.state.tkWidgetTemplateId)
                      }
                    >
                      Delete Widget.
                    </Button>
                  </Panel>
                </Collapse>
              </div>
            ) : (
              <p>
                No Threekit widget. Save your JS enviornment and Threekit org
                token to create it.
              </p>
            )}
            <br />
            <Collapse>
              <Panel header={"Help"}>
                <Help />
              </Panel>
            </Collapse>
            <br></br>
          </Content>
        </PageHeader>
        <Layout>
          <Content>
            <div className="container">
              {this.state.loading ? (
                <Spin />
              ) : (
                <Content>
                  <div className="alert-container">
                    {this.state.isSaved ? (
                      <Alert
                        message="Information Saved"
                        type="success"
                        closable
                        onClose={this.handleClose.bind(this)}
                      />
                    ) : (
                      <p></p>
                    )}
                    {this.state.submitErr ? (
                      <Alert
                        message="Please enter a valid URL"
                        type="error"
                        closable
                        onClose={this.handleClose.bind(this)}
                      />
                    ) : (
                      <p></p>
                    )}
                  </div>

                  <Form
                    {...formLayout}
                    layout={"horizontal"}
                    size={"small"}
                    className="settings-form"
                  >
                    {this.state.isJSLoading ? (
                      <Form.Item
                        label="Javascript Environment"
                        name="javascript"
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder="No Javascript Environment selected"
                          className="settings-input"
                          id="tkJS"
                          onChange={(e) => handleChange(e)}
                          value={this.state.tkJS}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Javascript Environment"
                        name="javascript"
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder={this.state.tkJS}
                          className="settings-input"
                          id="tkJS"
                          onChange={(e) => handleChange(e)}
                          value={this.state.tkJS}
                        />
                      </Form.Item>
                    )}
                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          this.state.hasScript
                            ? this.updateScript(
                                this.state.scriptId,
                                this.state.tkJS
                              )
                            : this.createScript(this.state.tkJS)
                        }
                      >
                        Save Javascript
                      </Button>
                    </Form.Item>

                    <Form.Item label="Org Token" name="token">
                      <Input
                        style={{ width: "80%" }}
                        placeholder={this.state.currentToken}
                        className="settings-input"
                        id="tkToken"
                        value={this.state.tkToken}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          this.state.hasTkWidgetTemplate
                            ? this.updateWidgetTemplate(
                                this.state.tkWidgetTemplateId,
                                this.state.tkToken
                              )
                            : this.createWidgetTemplate(this.state.tkToken)
                        }
                      >
                        Save Token
                      </Button>
                    </Form.Item>
                    {/* <Form.Item {...tailLayout}>
                
                      </Form.Item> */}
                  </Form>
                </Content>
              )}
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}
