import React from "react";

import logo from "./logo.svg";
import "./App.css";

const getApi = () => {
  // if (window.location.pathname == "/auth/install") {
  //   fetch("/auth/install" + window.location.search)
  //     .then((res) => res.text())
  //     .then((res) => {
  //       console.log(res);
  //       console.log("loaded");
  //     });
  // } else if (window.location.pathname == "/auth/load") {
  //   fetch("/auth/load" + window.location.search)
  //     .then((res) => res.text())
  //     .then((res) => {
  //       console.log("loaded");
  //       // fetch("/auth/getproducts" + window.location.search)
  //       //   .then((res) => res.json())
  //       //   .then((res) => {
  //       //     console.log(res);
  //       //   });
  //     });
  // }
};
getApi();
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
