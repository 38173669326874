import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import App from "./App";
import Home from "../src/screens/home";

import * as serviceWorker from "./serviceWorker";

// Screens
import Help from "../src/screens/help";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div>
        <Switch>
          {/* <Route path="/help" component={Help} /> */}

          <Route component={Home} />
        </Switch>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
